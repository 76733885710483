import { type UserRole } from '@aether/validation';
import { Link, useNavigate } from 'react-router-dom';

import { ButtonNew } from '../../ButtonNew';
import { DropdownMenu } from '../../DropdownMenu';
import { Icon } from '../../Icon';
import { IconButton } from '../../IconButton';
import { cn } from '../../utils';
import arabesqueAi from './arabesque-ai.svg';

type User = { displayName?: string | null; role: UserRole };

export function Header({
  children,
  user,
  host = '',
  isAuthRoute,
  isAuthenticated,
  className,
}: {
  children?: React.ReactNode;
  user?: User;
  host?: string | null;
  isAuthRoute: boolean;
  isAuthenticated: boolean;
  className?: string;
}) {
  // accounts for host being null
  if (!host) host = '';
  return (
    <div className={cn('grid w-full py-2 sm:grid-cols-[1fr_auto] md:text-left', className)}>
      <nav>
        {!isAuthRoute && (
          <div className="mr-2 inline-block">
            <div className="mr-2 inline-block align-middle">
              <MainMenu />
            </div>
            <Link to="/">
              <img src="/images/dragon.svg" alt="Arabesque AI" className="mr-2 inline-block h-8" />
              <div className="inline-grid items-baseline lg:grid-cols-2">
                <div className="mr-2 whitespace-nowrap text-xl font-bold">Portfolio Dragon</div>
                <div className="inline-block whitespace-nowrap text-xs text-gray-400">
                  Powered by <img src={arabesqueAi} alt="arabesque.ai" className="ml-1 inline-block h-3.5" />
                </div>
              </div>
              <div className="sr-only">Home</div>
            </Link>
          </div>
        )}
        {isAuthRoute && (
          <img className="h-auto w-[150px]" src="/images/arabesque-ai.svg" alt="logo" aria-hidden="true" />
        )}
        {children && <div className="inline-block">{children}</div>}
      </nav>
      {!isAuthRoute && <UserMenu user={user} isAuthenticated={isAuthenticated} host={host} />}
    </div>
  );
}

function UserMenu({ user, isAuthenticated, host }: { user?: User; isAuthenticated: boolean; host?: string }) {
  const navigate = useNavigate();

  if (!isAuthenticated || !user)
    return (
      <div className="hidden space-x-4 sm:flex">
        <ButtonNew variant="primary" iconBefore="ic:outline-add" asChild className="hover:bg-portfolio-400">
          <Link to={`${host}/auth/register`}>Create Account</Link>
        </ButtonNew>
        <ButtonNew variant="secondary" iconBefore="ic:outline-edit" asChild className="hover:bg-portfolio-100">
          <Link to={`${host}/auth/login`}>Sign In</Link>
        </ButtonNew>
      </div>
    );

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild data-testid="ac-UserMenu">
        <a className="flex cursor-pointer items-center justify-center">
          <Icon className=" text-[32px]" icon="ic:outline-account-circle" />
          <span className="ml-2">{user.displayName}</span>
        </a>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content align="end" sideOffset={8} className="z-20">
        <DropdownMenu.Item iconBefore="ic:outline-view-agenda" data-testid="ac-UserMenu-Profile">
          <Link to={`${host}/strategies`}>My portfolios</Link>
        </DropdownMenu.Item>
        <DropdownMenu.Item iconBefore="ic:outline-person" data-testid="ac-UserMenu-Profile">
          <Link to={`${host}/account/edit-profile`}>My profile</Link>
        </DropdownMenu.Item>
        <DropdownMenu.Separator />
        <DropdownMenu.Item iconBefore="ic:outline-logout" data-testid="ac-UserMenu-Logout">
          <Link to={`${host}/auth/logout`}>Log out</Link>
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

function MainMenu() {
  const navigate = useNavigate();
  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <IconButton size="medium" icon="ic:menu" variant="ghost" />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content className="z-20" align="start" side="bottom" variant="menu">
          <DropdownMenu.Item variant="menu" onSelect={() => navigate('/')}>
            Home
          </DropdownMenu.Item>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger variant="menu">About the Arabesque Group</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent variant="menu">
              <Link to="https://www.arabesque.com/ai/about-us/">
                <DropdownMenu.Item variant="menu">About Us</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/about-us/the-arabesque-group/">
                <DropdownMenu.Item variant="menu">The Arabesque Group</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/board-members/">
                <DropdownMenu.Item variant="menu">Our Board</DropdownMenu.Item>
              </Link>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger variant="menu">Services</DropdownMenu.SubTrigger>
            <DropdownMenu.SubContent variant="menu">
              <Link to="https://www.arabesque.com/ai/platform/#capabilities">
                <DropdownMenu.Item variant="menu">Portfolio Dragon</DropdownMenu.Item>
              </Link>
              <Link to="https://www.esgbook.com/">
                <DropdownMenu.Item variant="menu">ESG Book</DropdownMenu.Item>
              </Link>
              <Link to="https://www.arabesque.com/fund-centre/">
                <DropdownMenu.Item variant="menu">Fund Center</DropdownMenu.Item>
              </Link>
            </DropdownMenu.SubContent>
          </DropdownMenu.Sub>
          <Link to="https://www.arabesque.com/ai/ai-insights/">
            <DropdownMenu.Item variant="menu">Insights</DropdownMenu.Item>
          </Link>
          <Link to="https://www.arabesque.com/careers/">
            <DropdownMenu.Item variant="menu">Careers</DropdownMenu.Item>
          </Link>
          <Link to="https://www.arabesque.com/ai/contact-us/">
            <DropdownMenu.Item variant="menu">Contact Us</DropdownMenu.Item>
          </Link>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
}
