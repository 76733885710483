import * as React from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { cva } from 'class-variance-authority';

import { Icon } from '../Icon';
import { cn } from '../utils';

export type CheckboxProps = Omit<RadixCheckbox.CheckboxProps, 'onCheckedChange'> & {
  onCheckedChange?(checked: RadixCheckbox.CheckedState, prevChecked?: RadixCheckbox.CheckedState): void;
};

export const Checkbox = React.forwardRef<HTMLButtonElement, CheckboxProps>((props, forwardedRef) => {
  const { children, className, disabled, checked, onCheckedChange, ...rest } = props;
  return (
    <label className={cn(checkboxClasses({ disabled }), className)}>
      <RadixCheckbox.Root
        ref={forwardedRef}
        disabled={disabled}
        checked={checked}
        {...rest}
        onCheckedChange={newChecked => {
          onCheckedChange?.(newChecked, checked);
        }}
      >
        <CheckboxIcon checked={checked} />
      </RadixCheckbox.Root>
      {children && <span>{children}</span>}
    </label>
  );
});

export const checkboxClasses = cva(['inline-flex cursor-pointer items-center gap-2 text-xs font-normal leading-4'], {
  variants: {
    disabled: {
      true: 'text-grey-500',
    },
  },
  defaultVariants: { disabled: false },
});

export function CheckboxIcon({ checked }: { checked: RadixCheckbox.CheckboxProps['checked'] }) {
  return (
    <Icon
      className="m-0 select-none rounded text-base leading-4"
      icon={
        checked === 'indeterminate'
          ? 'ic:outline-indeterminate-check-box'
          : checked === true
            ? 'ic:outline-check-box'
            : 'ic:outline-check-box-outline-blank'
      }
    />
  );
}
