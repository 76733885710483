import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '../utils';

const headingStyle = cva('', {
  variants: {
    variant: {
      medium: 'text-xl font-medium',
    },
  },
  defaultVariants: {
    variant: 'medium',
  },
});

export type HeadingProps = React.ComponentProps<'h1'> &
  VariantProps<typeof headingStyle> & { level?: 1 | 2 | 3 | 4 | 5 | 6 };

export function Heading(props: HeadingProps) {
  const { level = 2, variant, className, ...rest } = props;
  const Comp = `h${level}` as 'h1';
  return <Comp {...rest} className={cn('m-0 p-0', headingStyle({ variant }), className)} />;
}
