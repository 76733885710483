import { useState } from 'react';
import { Button } from '@aether/ui/Button';
import { Dialog } from '@aether/ui/Dialog';
import { Heading } from '@aether/ui/Heading';

import { getCookieConsent, setCookieConsent } from './cookie';
import { optInTracking } from './tracker';

export function ConsentBanner() {
  const [consentRecorded, setConsentRecorded] = useState(Boolean(getCookieConsent()));
  const [dialogVisible, setDialogVisible] = useState(false);

  if (consentRecorded) return null;

  return (
    <div className="fixed bottom-0 z-50 flex w-full justify-center bg-portfolio-400 p-3 text-white print:hidden">
      <div className="flex flex-wrap items-center justify-center gap-4 sm:!flex-nowrap">
        <p className="text-center">
          This website uses cookies to provide you with the best browsing experience.{' '}
          <a
            href="#main"
            onClick={e => {
              e.preventDefault();
              setDialogVisible(true);
            }}
            className="whitespace-nowrap font-medium underline underline-offset-4"
          >
            Find out more
          </a>
          .
        </p>
        <Button
          onClick={() => {
            setCookieConsent('full');
            optInTracking();
            setConsentRecorded(true);
          }}
          className="sm:flex-0 flex-1"
        >
          Accept All
        </Button>
        <Button
          onClick={() => {
            setCookieConsent('min');
            setConsentRecorded(true);
          }}
          variantColor="accent"
          className="sm:flex-0 flex-1"
        >
          Strictly Necessary Only
        </Button>
      </div>
      <CookieConsentDialog open={dialogVisible} onOpenChange={setDialogVisible} />
    </div>
  );
}

function CookieConsentDialog({ open, onOpenChange }: { open: boolean; onOpenChange: (open: boolean) => void }) {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Content className="!max-w-5xl">
        <Dialog.Title asChild>
          <Heading className="mb-4">Cookie Policy</Heading>
        </Dialog.Title>
        <Dialog.Close />
        <Dialog.Body className="flex flex-col gap-3">
          <h3>What is a cookie?</h3>
          <p>
            A cookie is a small text file containing data that is stored on your computer or mobile device once you
            visit a website. Cookies vary in their duration, some are stored indefinitely and others for shorter times.
            Cookies can be created both by the site you are visiting - 'first party' cookies, and by other sites that
            interact with the one you are visiting - 'third party' cookies.
          </p>
          <h3>Privacy Overview</h3>
          <p>
            Privacy Overview This website uses cookies so that we can provide you with the best user experience
            possible. Cookie information is stored in your browser and performs functions such as recognising you when
            you return to our website and helping our team to understand which sections of the website you find most
            interesting and useful.
          </p>
          <h3>Arabesque's use of cookies</h3>
          <p>
            Arabesque uses cookies to improve your experience of our website, by remembering your user preferences for
            when you next visit the site. Some cookies are essential to allow you to navigate the website and the
            features available. Cookies are created both by Arabesque, which are first party cookies and by the third
            party sites listed in the 'Cookie list' below.
          </p>
          <p>
            Arabesque uses cookies to remember your previously selected settings such as; country and acceptance of our
            terms and conditions, please note that no personal data is collected. The expiry dates for Arabesque cookies
            vary between one day and two years. If you don't want Arabesque to place cookies on your device there are a
            number of options available to you. You can modify your own browser settings to either reject all cookies,
            only allow 'trusted' sites to write cookies, or only accept cookies from app.arabesque.ai
          </p>
          <p>
            Should you delete your cookie history, this may affect your experience of our website - the preferences
            selected on your previous visits to the site will not be remembered and you will have to reset these upon
            each visit to the site.
          </p>
          <h3>Types of cookies</h3>
          <p>
            Strictly Necessary: Some cookies are essential for the operation of this website. These cookies enable you
            to access secure areas of the website which would require log on details.
          </p>
          <p>
            Performance: This type of cookie allows us to analyse the use of the website allowing us to provide you with
            a high quality experience. This information will only be used to enhance user experiences.
          </p>
          <p>
            Functionality: Functionality cookies allow Arabesque to remember the settings from a user's last visit, i.e.
            relevant country and investor type. These cookies also enable users to access videos and audio recordings
            available.
          </p>
          <p>
            Targeting: Targeting cookies allow Arabesque to send you customised content via email and track which
            content you use.
          </p>
          <p>
            Persistent: Persistent cookies help websites remember your information and settings when you visit them in
            the future. This result in faster and more convenient access since, for example, you don't have to login
            again. Besides authentication, other website features made possible by persistent cookies include: language
            selection, theme selection, menu preferences, internal site bookmarks or favorites, among many others. On
            your first visit, the website is presented in default mode. During your visit, you select your preferences
            and these preferences are remembered, through the use of the persistent cookie, the next time you visit the
            site.
          </p>
          <p>
            Session: Session cookies are temporary cookie files, which are erased when you close your browser. When you
            restart your browser and go back to the site that created the cookie, the website will not recognize you.
            You will have to log back in (if login is required) or select your preferences/themes again if the site uses
            these features. A new session cookie will be generated, which will store your browsing information and will
            be active until you leave the site and close your browser.
          </p>
          <p>
            Technical: Technical cookies are used to broadcast a communication on a network of electronic communication.
          </p>
          <h3>Third Party Cookie List</h3>
          <h4>Firebase Authentication ('FA')</h4>
          <p>
            These cookies are used to enable a faster login process. FA supports authentication using passwords, popular
            federated identity providers like Google. Knowing your identity allows them to securely save your data in
            the cloud and provide the same personalised experience across all of your devices. When you log in to our
            Services, you explicitly request access to the content or functionality to which you are authorised. Without
            the use of an authentication token stored in a cookie you would have to provide a username/password on each
            page request. These cookies identify who you are for the length of the session and are only used on our Web
            App. For more information about Firebase and their cookies usage, visit{' '}
            <a
              className="text-accent"
              href="https://www.firebase.com/terms/privacy-policy.html"
              target="_blank"
              rel="noreferrer"
            >
              https://www.firebase.com/terms/privacy-policy.html
            </a>
          </p>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog.Root>
  );
}
