import React from 'react';
import * as RadixDialog from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';

import { IconButton } from '../IconButton';
import { cn } from '../utils';

export type DialogRootProps = RadixDialog.DialogProps;
const Root = RadixDialog.Root;

export type DialogTriggerProps = RadixDialog.DialogTriggerProps;
const Trigger = RadixDialog.Trigger;

export type DialogDescriptionProps = RadixDialog.DialogDescriptionProps;
const Description = RadixDialog.Description;

export type DialogContentProps = RadixDialog.DialogContentProps &
  VariantProps<typeof dialogStyles> & { overlayOpacity?: 'bg-opacity-5' | 'bg-opacity-50' };
function Content(props: DialogContentProps) {
  const { className, children, variant, fullscreen, overlayOpacity = 'bg-opacity-5', ...rest } = props;
  return (
    <RadixDialog.Portal>
      <RadixDialog.Overlay className={cn('fixed inset-0 z-40 bg-black', overlayOpacity)} />
      <RadixDialog.Content {...rest} className={cn(dialogStyles({ variant, fullscreen }), className)}>
        {children}
      </RadixDialog.Content>
    </RadixDialog.Portal>
  );
}

const dialogStyles = cva(
  'fixed left-1/2 top-1/2  z-50 flex -translate-x-1/2 -translate-y-1/2 animate-dialog flex-col',
  {
    variants: {
      variant: {
        unstyled: '',
        default: 'rounded bg-white p-6 shadow-card',
      },
      fullscreen: {
        true: 'h-screen max-h-screen w-screen',
        false: 'w-90vw max-h-[85vh] max-w-[450px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      fullscreen: false,
    },
  }
);

export type DialogBodyProps = React.ComponentProps<'div'>;
function Body(props: DialogBodyProps) {
  const { className, ...rest } = props;
  return <div className={cn('-mx-6 my-4 max-h-full overflow-auto px-6', className)} {...rest} />;
}

export type DialogTitleProps = RadixDialog.DialogTitleProps & VariantProps<typeof titleStyle>;
const Title = React.forwardRef<HTMLHeadingElement, DialogTitleProps>(function Title(props, ref) {
  const { className, variant, ...rest } = props;
  return <RadixDialog.Title ref={ref} className={cn(titleStyle({ variant }), className)} {...rest} />;
});
const titleStyle = cva('', {
  variants: {
    variant: {
      default: 'text-xl font-medium',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export type DialogCloseProps = RadixDialog.DialogCloseProps & VariantProps<typeof closeStyle>;
const Close = React.forwardRef<HTMLButtonElement, DialogCloseProps>(function Close(props, ref) {
  const {
    className,
    children = <IconButton size="small" variant="ghost" variantColor="secondary" icon="ic:outline-close" />,
    variant,
    ...rest
  } = props;
  return (
    <RadixDialog.Close ref={ref} asChild className={cn(closeStyle({ variant }), className)} {...rest}>
      {children}
    </RadixDialog.Close>
  );
});
const closeStyle = cva('', {
  variants: {
    variant: {
      default: 'absolute right-6 top-6',
      unstyled: '',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export const Dialog = { Root, Trigger, Title, Content, Body, Close, Description };
