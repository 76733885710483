import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { cn, useForwardedRef } from '../utils';

export type IconButtonProps = Omit<React.ComponentProps<'button'>, 'ref'> &
  VariantProps<typeof iconButtonClasses> & {
    asChild?: boolean;
    icon: string;
    className?: string;
  };

export const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(props, forwardedRef) {
    const { asChild, icon, children, variant, variantColor, size, disabled, loading, className, ...rest } = props;
    const Comp = (asChild ? Slot : 'button') as 'button';
    const ref = useForwardedRef(forwardedRef);
    return (
      <Comp
        ref={ref}
        className={cn('ac-btn', iconButtonClasses({ variant, size, variantColor, disabled, loading }), className)}
        disabled={Boolean(disabled || loading)}
        {...rest}
      >
        {icon && !loading && <Icon className={iconClasses({ size })} icon={icon} />}
        {loading && <Spinner className={iconClasses({ size })} />}
        <Slottable>{children}</Slottable>
      </Comp>
    );
  }
);

const iconButtonClasses = cva('focus:ouline-none inline-flex items-center justify-center rounded', {
  variants: {
    variant: {
      plain: 'shadow-card',
      ghost: '',
      text: '',
      outline: '',
    },
    size: {
      xsmall: 'h-4 w-4',
      smaller: 'h-6 w-6',
      small: 'h-7 w-7',
      medium: 'h-9 w-9',
      ml: 'h-11 w-11',
      large: 'h-14 w-14',
    },
    variantColor: {
      primary: '',
      accent: '',
      accentlight: '',
      secondary: '',
      warning: '',
      danger: '',
    },
    disabled: {
      true: 'cursor-not-allowed',
    },
    loading: {
      true: 'cursor-progress',
    },
  },
  compoundVariants: [
    {
      variant: 'plain',
      variantColor: 'primary',
      disabled: false,
      className: 'bg-grey-850 text-white ',
    },
    {
      variant: 'plain',
      variantColor: 'primary',
      loading: false,
      disabled: false,
      className: 'hover:bg-accent hover:text-white',
    },
    {
      variant: 'plain',
      variantColor: 'accent',
      disabled: false,
      className: 'bg-portfolio-500 text-white',
    },
    {
      variant: 'plain',
      variantColor: 'accent',
      loading: false,
      disabled: false,
      className: 'hover:bg-grey-850 hover:text-white',
    },
    {
      variant: 'plain',
      variantColor: 'secondary',
      disabled: false,
      className: 'bg-white text-black',
    },
    {
      variant: 'plain',
      variantColor: 'secondary',
      loading: false,
      disabled: false,
      className: 'hover:bg-grey-850 hover:text-white',
    },
    {
      variant: 'plain',
      variantColor: 'warning',
      disabled: false,
      className: 'bg-orange-400 text-white',
    },
    {
      variant: 'plain',
      variantColor: 'warning',
      loading: false,
      disabled: false,
      className: 'hover:bg-orange-600',
    },
    {
      variant: 'plain',
      variantColor: 'danger',
      disabled: false,
      className: 'bg-error-400 text-white',
    },
    {
      variant: 'plain',
      variantColor: 'danger',
      disabled: false,
      loading: false,
      className: 'hover:bg-error-600',
    },
    {
      variant: 'ghost',
      variantColor: 'primary',
      disabled: false,
      className: 'text-black',
    },
    {
      variant: 'ghost',
      variantColor: 'primary',
      loading: false,
      disabled: false,
      className: 'hover:bg-accent hover:text-white',
    },
    {
      variant: 'ghost',
      variantColor: 'accent',
      disabled: false,
      className: 'text-accent',
    },
    {
      variant: 'ghost',
      variantColor: 'accent',
      loading: false,
      disabled: false,
      className: 'hover:bg-accent hover:text-white',
    },
    {
      variant: 'ghost',
      variantColor: 'secondary',
      disabled: false,
      className: 'text-grey-500',
    },
    {
      variant: 'ghost',
      variantColor: 'secondary',
      loading: false,
      disabled: false,
      className: 'hover:text-black',
    },

    {
      variant: 'ghost',
      variantColor: 'warning',
      disabled: false,
      className: 'text-orange-500',
    },
    {
      variant: 'ghost',
      variantColor: 'warning',
      loading: false,
      disabled: false,
      className: 'hover:text-orange-600',
    },
    {
      variant: 'ghost',
      variantColor: 'danger',
      disabled: false,
      className: 'text-error-500',
    },
    {
      variant: 'ghost',
      variantColor: 'danger',
      disabled: false,
      loading: false,
      className: 'hover:text-error-600',
    },
    {
      variant: 'outline',
      variantColor: 'primary',
      disabled: false,
      className: 'border border-black text-black',
    },
    {
      variant: 'outline',
      variantColor: 'primary',
      disabled: false,
      loading: false,
      className: 'hover:border-grey-800 hover:text-grey-800',
    },
    {
      variant: 'outline',
      variantColor: 'accent',
      disabled: false,
      className: 'border border-accent text-accent',
    },
    {
      variant: 'outline',
      variantColor: 'accent',
      disabled: false,
      loading: false,
      className: 'hover:border-portfolio-800 hover:text-portfolio-800',
    },
    {
      variant: 'outline',
      variantColor: 'accentlight',
      disabled: false,
      className: 'border border-portfolio-300 text-portfolio-300',
    },
    {
      variant: 'outline',
      variantColor: 'accentlight',
      disabled: false,
      loading: false,
      className: 'hover:border-accent hover:text-accent',
    },
    {
      variant: 'outline',
      variantColor: 'secondary',
      disabled: false,
      className: 'border border-grey-600 text-grey-600',
    },
    {
      variant: 'outline',
      variantColor: 'secondary',
      disabled: false,
      loading: false,
      className: 'hover:border-text-black hover:text-black',
    },
    {
      variant: 'outline',
      variantColor: 'warning',
      disabled: false,
      className: 'border border-orange-600 text-orange-600',
    },
    {
      variant: 'outline',
      variantColor: 'warning',
      loading: false,
      disabled: false,
      className: 'hover:border-orange-700 hover:text-orange-700',
    },
    {
      variant: 'outline',
      variantColor: 'danger',
      disabled: false,
      className: 'border border-error-600 text-error-600',
    },
    {
      variant: 'outline',
      variantColor: 'danger',
      disabled: false,
      loading: false,
      className: 'hover:border-error-700 hover:text-error-700',
    },
    { variant: 'plain', disabled: true, className: 'bg-grey-50 text-grey-300' },
    { variant: 'ghost', disabled: true, className: 'text-grey-300' },
    { variant: 'outline', disabled: true, className: 'border border-grey-300 text-grey-300' },
    {
      variant: 'outline',
      disabled: true,
      variantColor: 'accent',
      className: 'border border-portfolio-100 text-portfolio-100',
    },
  ],
  defaultVariants: {
    variant: 'plain',
    size: 'medium',
    variantColor: 'primary',
    disabled: false,
    loading: false,
  },
});

const iconClasses = cva(['mi-outlined', 'm-0'], {
  variants: {
    size: {
      xsmall: 'text-base leading-4',
      smaller: 'text-base leading-4',
      small: 'text-xl leading-5',
      medium: 'text-2xl leading-6',
      ml: 'text-2xl leading-6',
      large: 'text-2xl leading-6',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});
